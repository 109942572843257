<script>
import { mask } from 'vue-the-mask'
import { onMounted, ref } from 'vue'
import Notify from '../../../notify'
import { useRoute, useRouter } from 'vue-router'
import { createSeller, getSeller, updateSeller } from './api'

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const multiAlertsRef = ref(null)
    const formSeller = ref({
      name: '',
      whatsapp: '',
      username: '',
      seller_approved: false,
      email: '',
      password: '',
      password_confirmation: ''
    })

    onMounted(() => {
      if (route.params.id) {
        getSellerDetails(route.params.id)
      }
    })

    async function getSellerDetails (idSeller) {
      try {
        const seller = await getSeller(Number(idSeller))
        formSeller.value = seller
      } catch (error) {
        Notify.create({
          title: 'Falha ao carregar sorteio',
          timer: 1500
        })
      }
    }

    async function submitSeller () {
      try {
        if (formSeller.value.password !== formSeller.value.password_confirmation) {
          Notify.create({
            title: 'Por favor verifique sua senha!',
            timer: 2500
          })
          return
        }

        !route.params.id ? newSeller(formSeller.value) : editSeller(route.params.id, formSeller.value)
      } catch (e) {
        const message = Object.values(e?.response?.data ?? {}).map(e => e[0]).join('<br>')
        multiAlertsRef.value.show(message)
      }
    }

    async function editSeller (id, formSeller) {
      await updateSeller(id, formSeller)
      Notify.create({
        title: 'Vendedor editado com sucesso!',
        timer: 1500
      })
      // changeTab('#websiteFormTab')
    }

    async function newSeller (formSeller) {
      const seller = await createSeller(formSeller)
      Notify.create({
        title: 'Vendedor criado com sucesso!',
        timer: 1500
      }).then(() =>
        router.push(`/sellers/${seller.id}/edit`)
      ).then(() =>
        changeTab('#websiteFormTab')
      )
    }

    function changeTab (tabId) {
      const secondTab = document.querySelector(tabId)
      secondTab.click()
    }

    return {
      formSeller,
      submitSeller,
      multiAlertsRef
    }
  },
  components: {
  },
  directives: {
    mask
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <form
        class="was-validated"
        @submit.prevent="submitSeller"
      >
        <!-- <multi-alerts ref="multiAlertsRef" /> -->
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label
                for="name"
                class="form-label"
              >
                Nome completo
              </label>
              <input
                id="name"
                v-model="formSeller.name"
                class="form-control"
                placeholder="Nome completo"
                required
              >
              <div class="invalid-tooltip">
                Campo obrigatório!
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label
                for="WhatsApp"
                class="form-label"
              >
                WhatsApp
              </label>
              <input
                id="WhatsApp"
                v-model="formSeller.whatsapp"
                class="form-control"
                placeholder="WhatsApp"
                required
              >
              <div class="invalid-tooltip">
                Campo obrigatório!
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label
                for="userName"
                class="form-label"
              >
                Nome de vendedor
              </label>
              <input
                id="userName"
                v-model="formSeller.username"
                class="form-control"
                placeholder="Nome do vendedor"
                required
              >
              <div class="invalid-tooltip">
                Campo obrigatório!
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label
                for="email"
                class="form-label"
              >
                E-mail
              </label>
              <input
                id="email"
                v-model="formSeller.email"
                type="email"
                class="form-control"
                placeholder="E-mail"
                required
              >
              <div class="invalid-tooltip">
                Campo obrigatório!
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label
                for="password"
                class="form-label"
              >
                Senha
              </label>
              <input
                id="password"
                v-model="formSeller.password"
                type="password"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label
                for="passwordConfirm"
                class="form-label"
              >
                Confirmar senha
              </label>
              <input
                id="passwordConfirm"
                v-model="formSeller.password_confirmation"
                type="password"
                class="form-control"
              >
            </div>
          </div>
        </div>
        <div class="form-check mb-4">
          <input
            id="horizontalLayout-Check"
            v-model="formSeller.seller_approved"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="horizontalLayout-Check"
          >
            Vendedor aprovado
          </label>
        </div>
        <div
          class="d-flex flex-wrap align-items-center justify-content-end"
        >
          <router-link
            :to="`/sellers`"
            class="btn btn-secondary me-2"
          >
            <i class="bx bx-left-arrow-circle me-1" />
            Cancelar
          </router-link>
          <button
            class="btn btn-primary"
            type="submit"
          >
            Salvar alterações
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { onMounted, ref } from 'vue'
import { getSellerProfile, updateProfile } from './api'
import Notify from '../../../notify'
import FileUploader from '@/components/file-uploader'
import { useRoute } from 'vue-router'

const themes = [
  'classic',
  'light',
  'ocean',
  'ruby',
  'amber',
  'tangerine',
  'jungle',
  'orchid',
  'flower'
]

export default {
  setup () {
    const store = ref()
    const route = useRoute()
    // const router = useRouter()

    const formFields = {
      sitename: {
        label: 'Nome do site',
        required: true
      },
      domains: {
        label: 'Domínios (separados por vírgula)',
        required: true
      },
      home_title: {
        label: 'Título de chamada exibido na página inicial',
        required: false
      },
      home_subtitle: {
        label: 'Subtítulo de chamada exibido na página inicial',
        required: false
      },
      contest_finished_message: {
        label: 'Mensagem exibida quando um sorteio encerrado é visualizado',
        required: false
      },
      contest_finished_link: {
        label: 'Link informativo sobre o sorteio encerrado',
        required: false
      },
      fb_pixel_id: {
        label: 'Facebook Pixel ID (separados por vírgula)',
        required: false
      },
      link_banner: {
        label: 'Link para o Banner',
        required: false
      },
      whatsapp_number: {
        label: 'Whatsapp',
        required: false
      },
      whatsapp_group: {
        label: 'Grupo Whatsapp',
        required: false
      },
      is_legal_seller: {
        label: 'Vendedor Legal',
        required: false
      },
      hide_facebook_pixel: {
        label: 'Carregar Pixel Facebook',
        required: false
      }
    }

    const uploadFields = {
      page_icon: {
        label: 'Ícone (favicon) de seu website',
        acceptedFiles: 'image/x-icon,image/png,image/svg',
        resizeWidth: 64,
        maxFilesize: 256
      },
      topbar_logo: {
        label: 'Logo da barra superior',
        accepted: 'image/png,image/jpg,image/jpeg',
        resizeHeight: 64,
        maxFilesize: 512
      },
      home_banner: {
        label: 'Banner da home page versão desktop',
        accepted: 'image/png,image/jpg,image/jpeg',
        resizeHeight: 400,
        maxFilesize: 512
      },
      home_banner_mobile: {
        label: 'Banner da home page versão mobile',
        accepted: 'image/png,image/jpg,image/jpeg',
        resizeWidth: 1080,
        maxFilesize: 512
      }
    }

    const formKeys = Object.keys(formFields).reduce((a, c) => {
      a[c] = null
      return a
    }, {})

    const uploadKeys = Object.keys(uploadFields).reduce((a, c) => {
      a[c] = null
      return a
    }, {})

    const config = ref({
      ...formKeys,
      ...uploadKeys
    })

    onMounted(() => {
      const params = {
        id: route.params.id
      }
      getSellerProfile(params)
        .then((data) => {
          config.value = data.config ?? {}
        })
    })

    const formOption = (field, key) => {
      return formFields[field][key]
    }

    async function submit () {
      updateProfile(route.params.id, { config: config.value })
        .then(() => {
          Notify.create({
            title: 'Configurações do website atualizas!',
            icon: 'success',
            timer: 2000
          })
          // router.push('/sellers')
        })
        .catch(() => {
          Notify.create({
            title: 'Ocorreu um erro ao atualizar as informações ',
            timer: 1500
          })
        })
    }

    const onUpload = (file, field) => {
      const images = JSON.parse(file.xhr.responseText)
      // eslint-disable-next-line
      const path = images.map(({ cdn_url }) => cdn_url)[0]
      config.value[field] = path
    }

    const getImageURL = (field, options) => {
      if (config.value[field]) {
        return config.value[field]
      }

      return `https://via.placeholder.com/300x51.jpg?text=${options.label}`
    }

    return {
      themes,
      config,
      store,
      submit,
      onUpload,
      formOption,
      formFields,
      uploadFields,
      getImageURL
    }
  },
  components: {
    FileUploader
  },
  directives: {
    mask
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <form
        class="was-validated"
        @submit.prevent="submit"
      >
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-md-12 mb-3">
                <label
                  for="website_theme"
                  class="form-label"
                >
                  Tema do website
                </label>
                <select
                  id="website_theme"
                  v-model="config.theme"
                  required
                  class="form-control"
                >
                  <option
                    v-for="name in themes"
                    :key="name"
                    :value="name"
                  >
                    {{ name }}
                  </option>
                </select>
              </div>
              <div
                v-for="field in Object.keys(formFields)"
                :key="field"
                class="col-md-12"
              >
                <div class="mb-3">
                  <label
                    for="name"
                    class="form-label"
                  >{{ formOption(field, 'label') }}</label>
                  <input
                    id="name"
                    v-model="config[field]"
                    class="form-control"
                    :placeholder="formOption(field, 'label')"
                    :required="formOption(field, 'required')"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div
              v-for="(options, field) in uploadFields"
              :key="field"
              class="row"
              style="border: 1px dashed #999; margin-top: 1rem;"
            >
              <div class="col-9 text-center">
                <div style="margin-bottom: .5rem;">
                  {{ options.label }}
                </div>
                <img
                  :src="getImageURL(field, options)"
                  style="max-height: 200px; max-width: 80%;"
                >
              </div>
              <div class="col-3">
                <file-uploader
                  title="Enviar"
                  :options="options"
                  :on="{
                    success: (file) => onUpload(file, field)
                  }"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-wrap align-items-center justify-content-end"
        >
          <router-link
            :to="`/sellers`"
            class="btn btn-secondary me-2"
          >
            <i class="bx bx-left-arrow-circle me-1" />
            Cancelar
          </router-link>
          <button
            class="btn btn-primary"
            type="submit"
          >
            Salvar alterações
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

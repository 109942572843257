<template>
  <div>
    <b-table
      id="bank-accounts-table"
      striped
      hover
      :fields="fields"
      :items="items"
    >
      <template v-slot:cell(seller)="row">
        {{ row.item.user.name }}
      </template>
      <template v-slot:cell(created_at)="row">
        {{ row.item.created_at ? $d(row.item.created_at, 'info') : null }}
      </template>
      <template v-slot:cell(credentials_changed_at)="row">
        {{ row.item.credentials_changed_at ? $d(row.item.credentials_changed_at, 'info') : 'Sem alterações' }}
      </template>
      <template v-slot:cell(account_verified_at)="row">
        {{ row.item.account_verified_at ? $d(row.item.account_verified_at, 'info') : 'Não verificada' }}
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          style="width: 100%;"
          :variant="row.item.account_verified_at ? 'outline-danger' : 'primary'"
          :disable="updating"
          @click="toggleVerify(row.item)"
        >
          {{ row.item.account_verified_at ? 'Desabilitar' : 'Habilitar' }}
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
      aria-controls="bank-accounts-table"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { getBankAccounts, toggleVerifyBankAccount } from './api'
import { watch, onMounted, ref } from 'vue'

const _fields = [
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'seller',
    label: 'Vendedor'
  },
  {
    key: 'name',
    label: 'Conta'
  },
  {
    key: 'created_at',
    label: 'Cadastrada em'
  },
  {
    key: 'credentials_changed_at',
    label: 'Credenciais alteradas em'
  },
  {
    key: 'account_verified_at',
    label: 'Conta verificadas em'
  },
  {
    key: 'actions',
    label: 'Ações',
    class: 'actions-column'
  }
]

export default {
  setup () {
    const currentPage = ref(1)
    const totalRows = ref(0)
    const perPage = ref(15)
    const items = ref([])
    const fields = ref(_fields)
    const route = useRoute()
    const updating = ref(false)

    const fetchBankAccounts = () => {
      getBankAccounts({
        user_id: route.params.id,
        page: currentPage.value
      }).then((data) => {
        totalRows.value = data.total
        items.value = data.data
      })
    }

    const toggleVerify = (bankAccount) => {
      updating.value = true
      toggleVerifyBankAccount(bankAccount.id).then((data) => {
        bankAccount.account_verified_at = data.account_verified_at
      }).finally(() => {
        updating.value = false
      })
    }

    onMounted(() => fetchBankAccounts())

    watch(currentPage, fetchBankAccounts)

    return {
      fields,
      items,
      perPage,
      currentPage,
      totalRows,
      updating,
      toggleVerify
    }
  }
}
</script>

<style>
td.actions-column {
  width: 20%;
}
</style>

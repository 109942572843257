import { axios } from '../../../axios'

export const getSellers = (params = {}) => {
  return axios.get('/admin/sellers', { params }).then(({ data }) => data)
}

export const createSeller = (params = {}) => {
  return axios.post('/admin/sellers', params).then(({ data }) => data)
}

export const updateSeller = (id, params = {}) => {
  return axios.put(`/admin/sellers/${id}`, params).then(({ data }) => data)
}

export const getSeller = (id) => {
  return axios.get(`/admin/sellers/${id}`).then(({ data }) => data)
}

export const getSellerProfile = (params = {}) => {
  return axios.get('/admin/sellers/get-seller-profile', { params }).then(({ data }) => data)
}

export const updateProfile = (id, params = {}) => {
  return axios.put(`/admin/sellers/update-profile/${id}`, params).then(({ data }) => data)
}

export const promoteAccount = (id, params = {}) => {
  return axios.put(`/admin/sellers/${id}/promote`, params).then(({ data }) => data)
}

export const demoteAccount = (id, params = {}) => {
  return axios.put(`/admin/sellers/${id}/demote`, params).then(({ data }) => data)
}

export const getBankAccounts = (params = {}) => {
  return axios.get('/admin/bank-accounts', { params }).then(({ data }) => data)
}

export const toggleVerifyBankAccount = (id) => {
  return axios.post(`/admin/bank-accounts/${id}/verify/toggle`).then(({ data }) => data)
}

<script>
import Layout from '@/views/layouts/main'
import { mask } from 'vue-the-mask'
import WebsiteConfig from './WebsiteConfig'
import PersonalData from './PersonalData'
import BankAccounts from './BankAccounts'
import { useRoute } from 'vue-router'
export default {
  setup () {
    const route = useRoute()
    return {
      route
    }
  },
  components: {
    Layout,
    PersonalData,
    WebsiteConfig,
    BankAccounts
  },
  directives: {
    mask
  }
}
</script>

<template>
  <Layout :pagetitle="'Cadastro de Vendedor'">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              {{ route.params.id ? 'Editar' : 'Adicionar' }} Vendedor
            </h5>
          </div>
        </div>
        <b-card no-body>
          <b-tabs
            card
            fill
            justified
            active-nav-item-class="text-primary"
          >
            <b-tab active>
              <template #title>
                <i class="bx bx-user-circle font-size-20" />
                <div> Dados pessoais </div>
              </template>
              <personal-data />
            </b-tab>
            <b-tab>
              <template #title>
                <i class="bx bx-clipboard font-size-20" />
                <div> Dados do Website </div>
              </template>
              <website-config v-if="route.params.id" />
            </b-tab>
            <b-tab>
              <template #title>
                <i class="bx bx-clipboard font-size-20" />
                <div> Contas bancárias </div>
              </template>
              <BankAccounts />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </Layout>
</template>
